import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBK03J7ItawJ3HUXPut7jbFWhVIHA8s6m4",
  authDomain: "omazda-website.firebaseapp.com",
  projectId: "omazda-website",
  storageBucket: "omazda-website.appspot.com",
  messagingSenderId: "863886438857",
  appId: "1:863886438857:web:647255e7181a61377ef818",
  measurementId: "G-VP0JZF1VFJ"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const currentDate = new Date();
const rnExperienceStart = new Date('2017-04-01T00:00:00');
const rnExperienceInYears = Number(currentDate.getFullYear())-Number(rnExperienceStart.getFullYear());
const webExperienceStart = new Date('2009-01-01T00:00:00');
const webExperienceInYears = Number(currentDate.getFullYear()) - Number(webExperienceStart.getFullYear());

window.onload = () => {
  const rnExperiene = document.querySelector('#rn-experience');
  rnExperiene.innerHTML = rnExperienceInYears;
  const webExperiene = document.querySelector('#web-experience');
  webExperiene.innerHTML = webExperienceInYears;
}
